<template>
	<div>
		<div>
			<headerTop></headerTop>
		</div>
		<div>
			<headerMin></headerMin>
		</div>
		<navlist :state="1"></navlist>
		<div class="index_content" ref="index">
			<div class="banner">
				<el-carousel :loop='true' trigger="click" :height="height + 'px'">
					<el-carousel-item v-for="(v,i) in banner_List" :key="i">
						<img class="banner_img" :src="v.image" alt />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="wrap">
				<!-- 健康知识 活动 促销 -->
				<div class="promotion">
					<div class="goods_titles">
						<i></i>
						<span>健康知识</span>
						<div class="all">
							<router-link to="./healthy">查看更多>></router-link>
						</div>
					</div>
					<div class="textlistbox">
						<div @click="Gotextinfor(v.id)" v-for="(v,i) in textlist" :key="i">
							<img :src="v.pic_url" alt />
							<p class="title">{{v.title}}</p>
							<p class="text-bottom">
								<span>阅读量：{{v.article_count}}</span>
								<span>时间：{{v.add_time |gettime}}</span>
							</p>
						</div>
					</div>

					<div class="goods_titles">
						<i></i>
						<span>活动专区</span>
						<div class="all">
							<router-link to="./promotion">查看更多>></router-link>
						</div>
					</div>
					<div class="goods">
						<div @click="GoDetails(v.id,v.shop_id)" v-for="(v,i) in activity_List" :key="i">
							<img :src="v.goods_img" alt />
							<p class="one">{{v.goods_name}}</p>
							<p class="money">
								￥{{v.price}}
								<span>￥{{v.price}}</span>
							</p>
						</div>
					</div>
					<div v-if="agentgoods.length>0" class="promotion">
						<div class="goods_titles">
							<i></i>
							<span>代理商专区</span>
							<div class="all">
								<router-link to="./agentShop">查看更多>></router-link>
							</div>
						</div>
						<div class="goods">
							<div @click="GoDetails(v.id,v.shop_id)" v-for="(v,i) in agentgoods" :key="i">
								<img :src="v.goods_img" alt />
								<p class="one">{{v.goods_name}}</p>
								<p class="money">
									￥{{v.price}}
									<span>￥{{v.price}}</span>
								</p>
							</div>
						</div>
					</div>
					<div class="recommend">
						<div class="goods_titles">
							<i></i>
							<span>推荐产品</span>
						</div>
						<div class="navs">
							<div @click="recommend_Tab(v,i)" :class="{ recommend_Active : recommendNavActive == i}" v-for="(v,i) in recommendNav"
							 :key="i">
								{{v.category_name}}
								<i></i>
								<span></span>
							</div>
							<div @click="GoAllGoods()">
								全部商品>>
								<i></i>
							</div>
						</div>
						<div class="goods">
							<div @click="gogoods(v.id)" v-for="(v,i) in  recommend_List" :key="i">
								<div class="goodsImg">
									<img :src="v.goods_img" alt />
								</div>
								<p class="one">￥ {{v.price}}</p>
								<p class="two">{{v.goods_name}}</p>
								<div class="bottom">
									<p>
										评论
										<span>{{v.commentSum}}</span>条
									</p>
									<button>立即购买</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="user_Top_Cart">
			<div @mouseover="userState = 1" @mouseout="userState = 0" :class="{ bg_cart:$user.state == 1}" class="cart">
				<img v-if="$user.state == 0" src="@/assets/image/right_ShoppingCart_icon_gray@2x.png" alt />
				<img v-if="$user.state == 1" src="@/assets/image/right_ShoppingCart_icon_white@2x.png" alt />
				<br />购物车
			</div>
			<div v-if="btnFlag" @click="backTop" class="Top">
				<img src="@/assets/image/right_TOP_icon@2x.png" alt />
			</div>
		</div>
		<div @mouseover="userState = 1" @mouseout="userState = 0" v-show="userState == 1" class="cart_box">
			<div class="cart_boxs">
				<div v-if="$user.state ==0" class="card_none">
					您未登录 请
					<span>登录</span>
				</div>
				<div v-if="$user.state ==1">
					<div v-for="(v,i) in cartList" :key="i" class="cart_list">
						<div class="fl imgs">
							<img :src="v.goods_img" alt />
						</div>
						<div class="fl txt">
							<p class="one">{{v.goods_name}}*{{v.num}}{{v.unit}}</p>
							<p class="one">单价:￥{{v.price}}</p>
						</div>
						<div class="fr">
							合计：￥{{v.price*1000* v.num/1000}}
							<span @click="cartDelt(v)">删除</span>
						</div>
					</div>
				</div>
			</div>
			<button @click="Gocart()">查看我的购物车</button>
		</div>
		<div class="bg1">
			<publicBottom></publicBottom>
			<socket v-if="isShowSocket" />
		</div>
	</div>
</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	// import landChecking from "@/components/land-checking.vue";
	import publicBottom from "@/components/public/public_bottom.vue";
	import socket from "./socket";
	import {
		getAllCate
	} from "@/request/index"; //  首页获取分类
	import {
		top_index
	} from "@/request/index"; //  首页获取推荐药品
	import {
		banner
	} from "@/request/index"; //  首页获取 轮播图
	import {
		getActiveGoods
	} from "@/request/index"; //  活动
	import {
		Goods_cart_index
	} from "@/request/index"; //查看当前门店下的购物车
	import {
		delCart
	} from "@/request/index"; // 购物车 删除 商品
	import navlist from "@/components/home_repect/navlist.vue";
	export default {
		components: {
			headerTop,
			headerMin,
			publicBottom,
			socket,
			navlist
		},
		props: [],
		data() {
			return {
				search: "",
				recommendNav: [], //推荐 NAV
				recommendNavActive: 0, // 推荐 active
				recommend_List: [], //推荐列表
				banner_List: [], //轮播图
				activity_List: [], //活动 列表
				userState: 0, //显示隐藏 购物车
				btnFlag: false, // 显示隐藏 回到顶部
				cartList: [], //购物车列表
				height: "400",
				isShowSocket: false,
				textlist: "",
				agentgoods: [],
				category_id:"",

			};
		},
		created() {
			this.start();
			if (this.$route.query.isRenderSocket) {
				this.isShowSocket = true;
			}
		},
		filters: {
			gettime(value) {
				let Y = "";
				let M = "";
				let D = "";
				let date = new Date(value * 1000);
				Y = date.getFullYear();
				M = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				D = date.getDay() < 10 ? "0" + date.getDay() : date.getDay();
				return Y + "-" + M + "-" + D;
			}
		},
		mounted() {
			window.addEventListener("scroll", this.scrollToTop);
			if (this.$user.state == 1) {
				this.init();
			}
			setTimeout(() => {
				let height = document.getElementsByClassName("banner_img")[0].offsetHeight;
				this.height = height + '';
				console.log(this.height)
			}, 800)
		},
		destroyed() {
			window.removeEventListener("scroll", this.scrollToTop);
		},
		methods: {
			gogoods(id){
				console.log(id,this.recommendNavActive);
				this.$router.push({name:"全部商品",query:{id:id,active:this.recommendNavActive,category_id:this.category_id}});
			},
			Gotextinfor(id){
				this.$router.push({name:"文章详情",query:{id:id}});
			},
			// 代理商专区
			getagentgoods() {
				this.$axios.post("api/user/getInfo", {
					token: this.$token
				}).then(res => {
					let daili = res.data.data.is_daili;
					console.log(daili);
					if (daili != 0) {
						this.$axios.post("api/goods/top_index2", {
							token: this.$token
						}).then(res => {
								console.log(res);
							if (res.data.code == 1) {
								this.agentgoods = res.data.data.data;
								console.log(this.agentgoods );
							}
						})
					}
				});

			},
			Gocart() {
				if (this.$user.state == 0) {
					this.$message.error("请登录后 查看");
				} else {
					this.$router.push({
						path: "/cart"
					});
				}
			},
			// 购物车删除商品
			cartDelt(e) {
				delCart({
					token: this.$user.token,
					CartId: e.id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "删除成功",
							type: "success"
						});
						this.cartList = [];
						this.init();
					}
				});
			},
			//  购物车加载
			init() {
				let shop_id = localStorage.getItem("lzyyShopId");
				Goods_cart_index({
					token: this.$user.token,
					shop_id: shop_id
				}).then(res => {
					let data = res.data.datas;
					this.cartList = data;
					console.log(this.cartList);
				});
			},
			// 点击图片回到顶部方法，加计时器是为了过渡顺滑
			backTop() {
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						that.scrollTop + ispeed;
					if (that.scrollTop === 0) {
						clearInterval(timer);
					}
				}, 16);
			},
			// 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			scrollToTop() {
				const that = this;
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (that.scrollTop > 60) {
					that.btnFlag = true;
				} else {
					that.btnFlag = false;
				}
			},
			GoDetails(id,shopId) {
				this.$router.push({
					path: "goodsDetails?id=" + id+"&shopId="+shopId
				});
			},
			recommend_Tab(v, i) {
				this.recommendNavActive = i;
				this.category_id=v.id;
				top_index({
					category_id: v.id
				}).then(res => {
					this.recommend_List = res.data.data;
					// 最多显示4条数据
					this.recommend_List = this.recommend_List.slice(0, 4);
				});
			},
			start() {
				let shopId = localStorage.getItem("lzyyShopId");
				// 获取健康知识文章列表
				this.$axios.post("/api/Article/getList", {
					category_id: 7
				}).then(res => {
					this.textlist = res.data.data.data.slice(0, 6);
				});
				//  轮播图
				banner({
					position_id: 2,
					limit_num: 5
				}).then(res => {
					this.banner_List = res.data;
				});
				//  活动
				getActiveGoods({
					
				}).then(res => {
					this.activity_List = res.data.data.slice(0, 5);
					console.log(this.activity_List);
				});
				// 分类 推荐
				// this.$axios.post("api/Goods_category/index",{
				// 	parent_id:0
				// }).then(res=>{
				// 	console.log(res);
				// });
				getAllCate({
					parent_id: 0
				}).then(res => {
					let datas = res.data;
					this.recommendNav = res.data;
					this.category_id=this.recommendNav[0].id;
					top_index({
						category_id: this.recommendNav[0].id
					}).then(res => {
						if (res.code == 1) {
							this.recommend_List = res.data.data;
							this.recommend_List = this.recommend_List.splice(0, 4);
						}
					});
				});
				this.getagentgoods();
			},
			GoAllGoods() {
				this.$router.push({
					path: "/allGoods"
				});
			}
		}
	};
</script>
<style lang="less" scoped>
	.card_none {
		margin-top: 75px;
		text-align: center;
		font-size: 16px;

		span {
			display: inline-block !important;
			color: #38eec1 !important;
		}
	}

	.cart_box {
		position: fixed;
		bottom: 16px;
		right: 58px;
		z-index: 9;
		width: 342px;
		height: 240px;
		background: #f7f7f7;
		border: 1px solid rgba(56, 238, 193, 1);
		text-align: right;

		img {
			width: 44px;
			height: 44px;
			margin-right: 6px;
		}

		button {
			margin-top: 10px;
			margin-right: 10px;
			width: 132px;
			height: 28px;
			background: rgba(56, 238, 193, 1);
			font-size: 16px;
		}

		.txt {
			width: 160px;
		}

		span {
			display: block;
			color: #cccccc;
		}

		.fr {
			text-align: right;
		}

		.cart_boxs {
			height: 193px;
			overflow-y: auto;
			border-bottom: 1px solid #000000;
			background: white;
		}

		.cart_list {
			height: 64px;
			border-bottom: 1px solid #000000;
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 10px;
			overflow: hidden;
		}
	}

	.bg_cart {
		background: rgba(56, 238, 193, 1) !important;
	}

	.user_Top_Cart {
		position: fixed;
		bottom: 120px;
		height: 136px;
		width: 58px;
		right: 0;
		z-index: 9;

		.cart {
			width: 58px;
			text-align: center;
			height: 78px;
			background: #e6e6e6;
			padding-top: 14px;
			font-size: 13px;
			color: #ffffff;

			img {
				width: 30px;
				height: 27px;
				margin-bottom: 4px;
			}
		}

		.Top {
			width: 58px;
			height: 58px;
			text-align: center;
			padding-top: 14px;
			background: rgba(102, 102, 102, 1);

			img {
				width: 28px;
				height: 30px;
			}
		}
	}

	// index
	.index_content {
		.banner {
			min-width: 1200px;

			img {
				min-width: 1200px;
				width: 100%
			}
		}

		.nav {
			margin-top: 40px;
			display: flex;
			justify-content: space-between;

			>div {
				border-radius: 8px;
				width: 232px;
				height: 255px;
				text-align: center;
				color: white;
			}

			h1 {
				margin-top: 20px;
				font-size: 30px;
				color: white;
				font-weight: 400;
			}

			h2 {
				font-size: 24px;
				margin-top: 12px;
				margin-bottom: 18px;
				color: white;
				font-weight: 400;
			}

			img {
				width: 62px;
				height: 78px;
				margin-bottom: 10px;
				color: white;
			}

			p {
				margin: auto;
				font-size: 22px;
				width: 124px;
				height: 34px;
				line-height: 34px;
				color: white;
				border-radius: 16px;
			}
		}

		.nav1 {
			background: linear-gradient(to right, #bbdfff, #97cdf9);

			p {
				background: linear-gradient(to right, #6b9efd, #4b7bd4);
			}
		}

		.nav2 {
			background: linear-gradient(to right, #68daff, #27b8ee);

			p {
				background: linear-gradient(to right, #4eaeff, #0f8bc5);
			}
		}

		.nav3 {
			background: linear-gradient(to right, #45efc5, #08d1a7);

			p {
				background: linear-gradient(to right, #7dede4, #10cbbc);
			}
		}

		.nav4 {
			background: linear-gradient(to right, #bbdfff, #97cdf9);

			p {
				background: linear-gradient(to right, #2b95ec, #0863af);
			}
		}

		.nav5 {
			background: linear-gradient(to right, #f7db8e, #fdb659);

			p {
				background: linear-gradient(to right, #ffddaf, #ffa530);
			}
		}

		// 活动 促销
		.promotion {
			margin-top: 120px;
			position: relative;
		}

		.goods_titles {
			margin-bottom: 60px;
			height: 60px;
			line-height: 60px;
			position: relative;
			text-align: center;
			font-size: 30px;
			font-weight: 600;

			.all {
				position: absolute;
				top: 70px;
				right: 0;
				font-size: 16px;
				color: #333333;
				cursor: pointer;
			}

			i {
				display: inline-block;
				height: 2px;
				width: 440px;
				background: #38eec1;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			span {
				display: block;
				width: 190px;
				background: white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.promotion>.goods {
			margin-bottom: 120px;
			display: flex;
			flex-wrap: wrap;

			>div {
				cursor: pointer;
				height: 240px;
				width: 20%;
				padding: 10px;
				border: 1px solid #e6e6e6;
				border-left: none;
			}

			>div:nth-of-type(1) {
				border-left: 1px solid #e6e6e6;
			}

			img {
				width: 100%;
				height: 150px;
			}

			.one {
				font-size: 14px;
				color: #000;
			}

			p {
				text-align: center;
				margin-top: 10px;
				color: #ff0000;
				font-size: 20px;

				span {
					font-size: 12px;
					color: #999999;
					text-decoration: line-through;
				}
			}
		}

		.promotion>.textlistbox {
			margin-bottom: 120px;
			display: flex;
			flex-wrap: wrap;

			>div {
				cursor: pointer;
				height: 240px;
				width: 20%;
				padding: 10px;
				border: 1px solid #e6e6e6;
				border-left: none;
			}

			>div:nth-of-type(1) {
				border-left: 1px solid #e6e6e6;
			}

			img {
				width: 100%;
				height: 150px;
			}

			.title {

				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.text-bottom {
				margin-top: 10px;
				display: flex;
				justify-content: space-between;

				span {
					font-size: 12px;
					color: #999999;
				}
			}
		}

		// 产品推荐
		.recommend {
			margin-bottom: 40px;
		}

		.recommend .navs {
			cursor: pointer;
			margin: auto;
			width: 1000px;
			height: 42px;
			font-size: 18px;
			padding-left: 20px;
			display: flex;
			border-bottom: 1px solid #e6e6e6;

			>div {
				box-sizing: content-box;
				padding-left: 14px;
				padding-right: 14px;
				line-height: 42px;
				position: relative;
			}

			i {
				position: absolute;
				top: 14px;
				right: -1px;
				display: block;
				width: 1px;
				height: 18px;
				border-right: 1px solid #e6e6e6;
			}

			// 推荐产品 选中
			.recommend_Active {
				color: #38eec1;
				border: 1px solid #e6e6e6;

				i {
					border: none !important;
				}

				span {
					position: absolute;
					bottom: -2px;
					left: 0;
					display: block;
					width: 100%;
					height: 4px;
					background: white;
				}
			}
		}

		.recommend>.goods {
			margin-top: 40px;
			overflow: hidden;

			>div {
				cursor: Pointer;
				width: 250px;
				height: 418px;
				padding: 6px;
				text-align: left;
				border: 1px solid #e6e6e6;
				position: relative;
				float: left;
				margin-right: 50px;
				margin-bottom: 20px;
			}

			.bottom {
				width: 100%;
				position: absolute;
				// left : 10px;
				bottom: 14px;
			}

			.goodsImg {
				width: 100%;
				height: 234px;
				margin-bottom: 10px;
			}

			img {
				width: 100%;
				height: 100%;
			}

			.one {
				font-size: 24px;
				color: #f00000;
				margin-bottom: 8px;
			}

			.two {
				font-size: 16px;
				margin-bottom: 4px;
			}

			p {
				font-size: 16px;
				margin-bottom: 10px;

				span {
					color: #f00000;
				}
			}

			button {
				display: block;
				margin: auto;
				width: 208px;
				height: 32px;
				background: #f00000;
				font-size: 16px;
				border-radius: 4px;
			}
		}
	}
</style>
